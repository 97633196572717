@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Roboto", sans-serif;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

html {
  @apply scroll-smooth;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  @apply bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent;
}

.react-tel-input .flag-dropdown .selected-flag {
  @apply bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent;
}

.special-label {
  @apply hidden;
}

.direct-toggle:checked ~ .dot {
  @apply translate-x-full bg-gold;
}

.react-date-picker__wrapper {
  border: none !important;
}
.full-h-w{
  height: 100% !important;
  width: 100% !important;
}
