.separater_white { 
    border: 0; 
    height: 2px; 
    width: 90%;
    margin: auto;
    background-image:-webkit-linear-gradient(left, transparent, rgb(255, 255, 255), transparent) !important;
  }

  .separater_black { 
    border: 0; 
    height: 2px; 
    width: 90%;
    margin: auto;
    background-image:-webkit-linear-gradient(left, transparent, rgb(0, 0, 0), transparent) !important;
  }